<template>
  <div class="modal-dim" :class="{ on: on }">
    <div class="modal-wrap">
      <div class="modal-paging">
        <button class="btn-modal-prev"></button>
        <button class="btn-modal-close" @click="close"></button>
        <button class="btn-modal-next"></button>
      </div>
      <div class="portfolio-detail-wrap">
        <Swiper
          :modules="modules"
          :virtual="true"
          :centeredSlides="true"
          :spaceBetween="1300"
          :initialSlide="page"
          :allow-touch-move="false"
          :navigation="{ nextEl: `.btn-modal-next`, prevEl: `.btn-modal-prev` }"
        >
          <swiper-slide
            class="swiper-slide"
            v-for="(pofol, index) in portfolioList"
            :key="pofol.portfolioNo"
            :virtual-index="pofol.portfolioNo"
          >
            <div class="modal">
              <div class="portfolio-detail" :key="`inner_${pofol.portfolioNo}`">
                <div class="portfolio-detail-inner">
                  <div class="mockup-slide">
                    <div class="control-prev dev-cursor-pointer" :class="`control-prev-${index}`"></div>
                    <swiper
                      class="mockup-slide-ul"
                      :modules="innerModules"
                      :loop="true"
                      :navigation="{ nextEl: `.control-next-${index}`, prevEl: `.control-prev-${index}` }"
                      :pagination="{ el: `.swiper-pagination-${index}`, clickable: true }"
                    >
                      <swiper-slide class="mockup-slide-li" v-for="img in pofol.imageList" :key="img.storedFileName">
                        <img :src="ConstCode.getImagePath(img.storedFileName, 'origin')" alt="image" />
                      </swiper-slide>
                      <!-- 페이지네이션 -->
                      <div class="swiper-pagination" :class="`swiper-pagination-${index}`"></div>
                    </swiper>
                    <div class="control-next dev-cursor-pointer" :class="`control-next-${index}`"></div>
                  </div>
                </div>
                <div class="portfolio-detail-inner">
                  <div class="portfolio-detail-area">
                    <div class="title-wrap">
                      <p>{{ pofol.categoryName }}</p>
                      <h2 class="title">{{ pofol.projectName }}</h2>
                    </div>
                    <ul class="portfolio-info-wrap">
                      <li>
                        <p class="portfolio-info-ttl">Platform</p>
                        <div class="inner">
                          <div class="tag-wrap">
                            <p class="tag" v-for="platform in pofol.platform.split(',')" :key="platform.trim()">
                              {{ platform.trim() }}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <p class="portfolio-info-ttl">Technique</p>
                        <div class="inner">
                          <div class="tag-wrap">
                            <div class="tag" v-for="tag in tagSplit(pofol)" :key="tag">
                              {{ tag }}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="portfolio-info-txt">
                      <!--<h6 class="title">{{ pofol.projectSimpleDescription }}</h6>-->
                      <pre class="pre-txt" v-html="pofol.projectDescription"></pre>
                    </div>
                  </div>
                  <div class="contact-btn-box">
                    <div class="btn-wrap">
                      <button class="btn gray" @click="telTo">
                        <img src="@/assets/static/images/ico-call-gr90.svg" alt="전화문의" />
                        <span>전화문의</span>
                      </button>
                      <button class="btn kakao" @click="goKakaoChannel">
                        <img src="@/assets/static/images/ico-kakao.svg" alt="카카오 채널" />
                        <span>카카오 채널</span>
                      </button>
                    </div>
                    <button class="quote-contact-btn" @click="goEstimate">
                      <span>견적 문의</span>
                      <i class="ico-arrow-right-white"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <!-- 메인 페이지네이션 -->
          <div class="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, defineEmits, defineProps, onMounted, ref, toRefs } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import { useRouter } from 'vue-router';
import ConstCode from '@/common/constants/ConstCode';
import { useStore } from 'vuex';
import { telTo, goKakaoChannel } from '@/common/utils/suppliment';

const { getters } = useStore();
const { push } = useRouter();
const emits = defineEmits(['close']);
const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  list: {
    type: Array,
    required: true
  }
});

onMounted(() => {
  globalThis.addEventListener('keydown', event => {
    if (event.key === 'Escape') {
      close();
    }
  });
  const timeout = setTimeout(() => {
    on.value = true;
    clearTimeout(timeout);
    const scrollTarget = document.getElementsByTagName('html')[0];
    if (!scrollTarget) return;
    scrollTarget.style.overflowY = 'hidden';
  }, 50);
});

onBeforeMount(() => {
  page.value = props.index;
});

onBeforeUnmount(() => {
  const scrollTarget = document.getElementsByTagName('html')[0];
  if (!scrollTarget) return;
  scrollTarget.style.overflowY = 'auto';
  const timeout = setTimeout(() => {
    on.value = false;
    clearTimeout(timeout);
  }, 50);
});

// Swiper settings
const modules = [Navigation, Pagination, Virtual];
const innerModules = [Navigation, Pagination];

const isMobile = computed(() => {
  return !ConstCode.isMobile();
});
const on = ref(false);
const portfolio = ref({});
const page = ref();
const { list } = toRefs(props);
const portfolioList = computed(() => [...list.value]);
const tagSplit = ({ tag }) => tag.split(',').map(elem => elem.trim());

const goEstimate = () => {
  close();
  push('/estimate');
};

const close = () => {
  on.value = false;
  const timeout = setTimeout(() => {
    emits('close');
    clearTimeout(timeout);
  }, 500);
};
</script>

<style scoped lang="scss">
.modal-dim {
  z-index: 100;
}
</style>
