<template>
  <!--헤더-->
  <header class="header">
    <div class="inner" v-show="routeLayout.isHeader">
      <!--로고-->
      <router-link class="hd-logo" to="/main">
        <h1 class="logo"></h1>
      </router-link>
      <!--햄버거메뉴-->
      <input type="checkbox" id="btn-hamburger-wrap" v-model="modelValue" @change="navCheck" />
      <label for="btn-hamburger-wrap" class="btn-hamburger-wrap">
        <span class="btn-hamburger">
          <span class="icon"></span>
        </span>
      </label>

      <!--GNB-->
      <nav class="nav">
        <ul class="nav-gnb">
          <li :class="{ on: $route.path.includes('/main') }">
            <router-link to="/main" tag="a">회사소개</router-link>
          </li>
          <li :class="{ on: $route.path.includes('/portfolio') }">
            <router-link to="/portfolio" tag="a">포트폴리오</router-link>
          </li>
          <li :class="{ on: $route.path.includes('/production') }">
            <router-link to="/production/info" tag="a">서비스</router-link>
          </li>
          <li class="mo" :class="{ on: $route.path.includes('/directions') }">
            <a @click="openMap">오시는 길</a>
          </li>
          <!--오시는길: 모바일에서만 노출됩니다.-->
          <li :class="{ on: $route.path.includes('/faq') }">
            <router-link to="/faq" tag="a">FAQ</router-link>
          </li>
        </ul>
        <div class="nav-contact">
          <h3 class="company-number">
            <a @click="telTo">1544-6821</a>
          </h3>
          <div class="btn-contact"><router-link to="/estimate" tag="a">견적문의</router-link></div>
        </div>
      </nav>
    </div>
  </header>
  <!--//헤더-->
</template>

<script setup>
import { computed, getCurrentInstance, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Util from '@/common/utils/Util';
import store from '@/store';
import CommonSvc from '@/common/service/CommonSvc';
import { telTo } from '@/common/utils/suppliment';
import { modalAlertNames } from '@/components/common/globalComponent';

const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const { getters, dispatch } = useStore();
const state = reactive({
  isShowMyMenu: false
});
const routeLayout = computed(() => getters['layout/getRouteLayout']);

const openMap = () => {
  const payload = {};
  payload.component = modalAlertNames.MAP_AREA_NEW;
  dispatch('layout/pushModalAlertComponent', payload);
};
const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const navCheck = event => {
  let value = event.target.value;
  emits('update:modelValue', value === 'on');
};

const userData = computed(() => getters['user/getData']);
const fnMain = async () => {
  if (route.path === '/main') {
    location.reload();
  } else {
    await router.push({ path: '/main', query: { isRoot: 1 } });
  }
};
const fnLoginPage = async () => {
  await router.push({ path: '/login' });
};
const fnBack = () => {
  router.go(-1);
};

const fnDownloadFile = async (path, name) => {
  let params = {};
  params.fileName = name;
  params.filePath = path;
  await CommonSvc.downloadFile(params).then(res => {
    if (!Util.isEmpty(name)) {
      const blob = new Blob([res.data], {
        type: res.data.type,
        encoding: 'UTF-8'
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = name;
      a.target = '_blank';
      a.click();
    }
  });
};

const fnSideBar = async () => {
  await store.dispatch('layout/updateIsSideBar', { isSideBar: true });
};
const fnMove = async () => {
  window.open('https://pre.appg.co.kr/', '_blank');
};
</script>

<style scoped lang="scss"></style>
<!--
homepage-vue
Created at 2024-03-15
-->
