import Env from '@/common/constants/Env_prod.json';
import EnvStage from '@/common/constants/Env_stage.json';
import EnvProd from '@/common/constants/Env_prod.json';
import Util from '@/common/utils/Util';
// import { keyBy } from 'lodash'

let CODE_ENV_LIST = EnvProd;
if (process.env.NODE_ENV === 'production') {
  CODE_ENV_LIST = EnvProd;
} else if (process.env.VUE_APP_MODE === 'stage') {
  CODE_ENV_LIST = EnvStage;
}

const isMobile = () => {
  let body = document.getElementsByTagName('body');
  return body[0].clientWidth < 1000;
};

const BUDGET_LIST = [
  '잘 모르겠습니다',
  '1,000만원 이하',
  '1,000만원 ~ 2,000만원',
  '2,000만원 ~ 3,000만원',
  '3,000만원 ~ 5,000만원',
  '1,000만원 ~ 1억원',
  '1억원 이상'
];

const SITE_LINK_TYPE = {
  NONE: 0,
  VIDEO: 1,
  HOMEPAGE: 2
};

const FAQ_CATEGORY_TYPE = [
  { value: '제작안내', label: '제작안내' },
  { value: '제작문의', label: '제작문의' },
  { value: '제작과정', label: '제작과정' },
  { value: '부가서비스', label: '부가서비스' },
  { value: '호스팅', label: '호스팅' },
  { value: '견적의뢰', label: '견적의뢰' },
  { value: '유지보수', label: '유지보수' },
  { value: '솔루션', label: '솔루션' }
];

const PLATFORM = [
  { value: '', label: '전체' },
  { value: 'Android', label: '안드로이드' },
  { value: 'IOS', label: 'iOS' },
  { value: 'Mobile Web', label: '모바일 웹' },
  { value: 'Homepage', label: '홈페이지' }
];

const ConstCode = function() {
  return {
    KAKAO_CHANNEL: 'https://pf.kakao.com/_FtxoxhG/chat',
    NAVER_MAP:
      'https://map.naver.com/p/directions/-/14142071.1242287,4510049.288602,%EC%B2%AD%EA%B4%91%EB%B9%8C%EB%94%A9,1239321879,PLACE_POI/-/transit?c=14.00,0,0,0,dh',
    KAKAO_MAP:
      'https://map.kakao.com/?SP=RIS&map_type=TYPE_MAP&map_hybrid=false&sName=&eName=%EC%96%B8%EC%A3%BC%EB%A1%9C%20550&eX=508927.0&eY=1113275.0',
    CODE_ENV_LIST,
    BUDGET_LIST,
    SITE_LINK_TYPE,
    FAQ_CATEGORY_TYPE,
    PLATFORM,
    isMobile,
    getImagePath: function(path, size = 'origin') {
      if (Util.isEmpty(path)) return '';
      return `${CODE_ENV_LIST.FILE_SERVER_URL}/${size}/${path}`;
    },
    getFilePath: function(path = '') {
      if (Util.isEmpty(path)) return '';
      return `${CODE_ENV_LIST.FILE_SERVER_URL}${path}`;
    }
  };
};

// // 본인인증
// let windowObj
// let vmFunction
// let vmFunctionName
// window.addEventListener('message', function({ data }) {
//   if (!Util.isEmpty(data.source) && data.source === vmFunctionName) {
//     // 본인인증 콜백 message 처리
//     if (windowObj) {
//       windowObj.close()
//     }
//     vmFunction(data.params)
//   }
// })

export default ConstCode();
